import axios from "axios";
import { tiffDataURL } from "./tiff-tool";


export const loadImageAsDataURL = (src) => {
    if (!src) return Promise.reject(new Error('the src is empty'))

    const protocol = src.split(':')[0].toLowerCase()
    if (!['http', 'https'].includes(protocol)) {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.src = src
            img.onerror = reject
            img.onload = () => {
                return resolve(img.currentSrc)
            }
        })
    } else {
        return new Promise((resolve, reject) => {
            axios.get(src, {
                responseType: 'blob'
            }).then(response => {
                const reader = new FileReader()
                reader.readAsDataURL(response.data)
                reader.onerror = reject
                reader.onload = () => {
                    const dataURL = reader.result
                    const matchDataURL = /^data:(.*?);base64,(.*)$/.exec(dataURL)
                    const mimeType = matchDataURL?.at(1)
                    const imageType = /^image\/(.*)$/.exec(mimeType)?.at(1) ||
                        /^application\/x-(.*)$/.exec(mimeType)?.at(1)
                    if (!imageType) {
                        return reject(new Error(`using unsupported mime: ${mimeType}`))
                    }
                    if (['tif', 'tiff'].includes(imageType.toLowerCase())) {
                        const base64Content = matchDataURL?.at(2)
                        if (!base64Content) {
                            return reject(new Error('got empty tiff file'))
                        }
                        const binary = window.atob(base64Content)
                        const binarySize = binary.length
                        const bytes = new Uint8Array(binarySize)
                        // There may be some way to speed up, but I didn't find
                        for (let i = 0; i < binarySize; ++i) {
                            bytes[i] = binary.charCodeAt(i)
                        }
                        const data = bytes.buffer
                        const result = tiffDataURL(data)
                        if (result instanceof Error) {
                            return reject(result)
                        } else {
                            return resolve(result)
                        }
                    }
                    const img = new Image()
                    img.src = dataURL
                    img.onerror = reject
                    img.onload = () => {
                        return resolve(img.currentSrc)
                    }
                }
            }).catch(reason => {
                const img = new Image()
                // reload again to avoid axios raise but img not block
                // has been blocked by CORS policy:
                //     No 'Access-Control-Allow-Origin' header is present on the requested resource.
                // only works while developing
                img.src = src
                img.onerror = reject
                img.onload = () => {
                    return resolve(img.currentSrc)
                }
            })
        })
    }
}

export const getImageMimeType = (src, method = undefined) => {
    if (!method) {
        method = 'head'
    }
    return new Promise((resolve, reject) => {
        axios[method](src
        ).then(response => {
            const contentType = response.headers['content-type']
            const imageType = /^image\/(.*)$/.exec(contentType)?.at(1) ||
                /^application\/x-(.*)$/.exec(contentType)?.at(1)
            resolve(imageType ? imageType.toLowerCase() : '')
        }).catch(reason => {
            resolve(undefined)
        })
    })
}