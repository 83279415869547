import React from 'react';
import AnnotationOperation from '@labelbee/lb-components';
import '@labelbee/lb-components/dist/index.css';
import { baseURL } from '../../lib/api';
import { Alert, Skeleton } from 'antd';
import axios from 'axios';
import { Base64 } from 'js-base64';

import Toastify from 'toastify-js'

import { loadImageAsDataURL, getImageMimeType } from '../../lib/image-loader'

const axiosInstance = axios.create({baseURL: baseURL})
const LoadFileList = async (dataset, page, pageSize, viewAugmentation) => {
  const imageExtend = viewAugmentation ? 1 : 0
  return axiosInstance.post(
    '/front/data/filelist',
    {pages: page + 1, pagesize: pageSize, dataset_id: dataset.id, image_extend: imageExtend},
    {headers: {token: dataset.token}}
  ).then((response) => {
    if (response.data.code !== 0) {
      const message = `API response [${response.data.code}]:${response.data.msg}`
      ShowToast(message)
      return Promise.reject(new Error(message))
    }
    const list = response.data.data.list
    const query = imageExtend ? `&image_extend=${imageExtend}` : 0
    const salt = dataset.salt ? `&salt=${encodeURIComponent(dataset.salt)}` : ''
    const result = list.map(e => {
      const url = `${baseURL}/front/data/image/download?index=${e.id}&id=${dataset.id}&token=${dataset.token}` +
          query + salt
      return {
        id: e.id,
        result: Base64.decode(e.result_base64),
        url
      }
    })
    return {result, total: response.data.data.total}
  }).catch(reason => {
    ShowToast(`LoadFileList failed: ${reason}`)
  })
}

const ShowToast = (s) => {
  Toastify({
    text: s,
    duration: 3000,
    destination: "https://github.com/apvarun/toastify-js",
    newWindow: true,
    close: true,
    gravity: "top", // `top` or `bottom`
    position: "left", // `left`, `center` or `right`
    stopOnFocus: true, // Prevents dismissing of toast on hover
    style: {
      'background-color': 'rgba(255, 0, 0, 0.8)',
      position: 'absolute',
      width: '100vw',
      zIndex: 2000,
    }
  }).showToast();
}

const SaveFileSingle = async (dataset, data) => {
  const id = data.id;
  const result_base64 = Base64.encode(data.result);
  axiosInstance.post(
    '/front/data/result/save',
    {
      dataset_id: dataset.id,
      filelist: [
        {id, result_base64}
      ]
    },
    {headers: {token: dataset.token}}
  ).then((response) => {
    if (response.data.code !== 0) {
      const message = `API response [${response.data.code}]:${response.data.msg}`
      ShowToast(message)
      return Promise.reject(new Error(message))
    }
  }).catch(reason => {
    ShowToast(`Save data id=${id} failed: ${reason}`)
  })
}

const Annotation = (props) => {
  const { error, ready, dataset, goBack, jsonStepList, step, viewAugmentation, lang } = props;

  // const exportData = (data) => {
  //   console.log('exportData', data);
  // };

  const onSubmit = (data) => {
    // console.log('submitData', data);
    SaveFileSingle(dataset, data[0])
  };

  const onSave = (data, index, imgList) => {
    // console.log('save', data, index, imgList);
    SaveFileSingle(dataset, data)
  };

  const dataInjectionAtCreation = (data) => {
    return {
      testDataInjection: 1,
    };
  };

  const loadFileList = (page, pageSize) => {
    return new Promise((resolve) => {
      LoadFileList(dataset, page, pageSize, viewAugmentation).then(async (list) => {
        const promises = list.result.map(async item => {
          try {
            const src = item.url
            // const dataURL = await loadImageAsDataURL(src)
            // Object.assign(item, { url: dataURL })
            const imageType = await getImageMimeType(src) ?? await getImageMimeType(src, 'get')
            if (['tif', 'tiff'].includes(imageType)) {
              const dataURL = await loadImageAsDataURL(src)
              Object.assign(item, { url: dataURL })
            }
            return item
          } catch (e) {
            return item
          }
        })
        const result = await Promise.all(promises)
        resolve({ fileList: result, total: list.total });
      })
    });
  };

  const onPageChange = (nextIndex) => {
    console.log('pageChanged', nextIndex);
  };

  const changeLang = (lang, timeout) => {
    timeout ||= 200
    const langMap = new Map([
      ['en', '.bee-langEN'],
      ['cn', '.bee-langCN'],
      ['zh', '.bee-langCN']
    ])
    if (!langMap.has(lang)) {
      lang = 'en' // default using en
    }
    const element = document.querySelector(langMap.get(lang))
    if (element) {
      element.click()
      return
    }
    setTimeout(() => changeLang(lang, timeout), timeout)
  }

  if (lang && !error && ready) {
    const shortLang = String(lang).substring(0, 2).toLowerCase()
    console.log(`Change i18n to ${shortLang}`)
    changeLang(shortLang)
  }


  return (
    <div>
    {
      error
      ? <Alert
          style={{position: 'fixed', top: 0, left: 0, width: '100vw', zIndex: 1000}}
          message="Internal Error" type="error" showIcon closable description={error}></Alert>
      : (
        ready
        ? <AnnotationOperation
            // exportData={exportData}
            headerName={dataset.name}
            onSubmit={dataset.readonly ? undefined : onSubmit}
            // imgList={fileList}
            pageSize={10}
            loadFileList={loadFileList}
            goBack={goBack}
            stepList={JSON.parse(jsonStepList)}
            step={dataset.readonly ? undefined : step}
            onPageChange={onPageChange}
            onSave={dataset.readonly ? undefined : onSave}
            dataInjectionAtCreation={dataInjectionAtCreation}
            // renderEnhance={renderEnhance}
            />
        : <Skeleton active style={{boxSizing: "border-box", padding: '2em'}}/>
        )
    }
    </div>
  );
};
export default Annotation;
