import './App.css';
import 'antd/dist/antd.css';
import Annotation from './components/Annotation';
import qs from 'qs';
import { cTool } from '@labelbee/lb-annotation';
import { baseURL } from './lib/api';
import axios from 'axios';
import { useState } from 'react';

const { EToolName } = cTool;

const rectToolConfig = {
  showConfirm: false,
  skipWhileNoDependencies: false,
  drawOutsideTarget: false,
  copyBackwardResult: true,
  minWidth: 1,
  minHeight: 1,
  isShowOrder: true,
  filterData: ['valid', 'invalid'],
  attributeConfigurable: true,
  attributeList: [
    // { key: '狗', value: 'dog' }
  ],
  textConfigurable: true,
  textCheckType: 0,
  customFormat: '',
};

const buildStepConfig = () => {
  return {
    step: 1,
    dataSourceStep: 0,
    tool: EToolName.Rect,
    config: JSON.stringify(rectToolConfig)
  };
}

const buildStepList = () => {
  return [ buildStepConfig() ]
}

const App = () => {
  // document.title = ''

  const datasetName = qs.parse(window.location.search, { ignoreQueryPrefix: true, comma: true }).name;
  const datasetID = qs.parse(window.location.search, { ignoreQueryPrefix: true, comma: true }).id;
  const accessToken = qs.parse(window.location.search, { ignoreQueryPrefix: true, comma: true }).token;
  const augment = qs.parse(window.location.search, { ignoreQueryPrefix: true, comma: true }).augment;
  const salt = qs.parse(window.location.search, { ignoreQueryPrefix: true, comma: true }).salt;
  const readonly = qs.parse(window.location.search, { ignoreQueryPrefix: true, comma: true }).readonly;
  const lang = qs.parse(window.location.search, { ignoreQueryPrefix: true, comma: true }).lang;

  // console.log('datasetName', datasetName)

  const dataset = {
    name: datasetName,
    id: Number(datasetID),
    token: accessToken,
    salt: salt,
    readonly: !!augment || !!readonly
  }

  const [ stateJsonStepList, updateJsonStepList ] = useState(JSON.stringify(buildStepList()))
  const [ stateError, updateError] = useState("")
  const [ stateReady, updateReady] = useState(false)

  if (!stateError) {
    if (!datasetID) {
      updateError('Parameter error, id needs to be set in query')
    } else if (!accessToken) {
      updateError('Login information cannot be obtained. Please re-enter the training system!')
    } else {
      // query labels
      const axiosInstance = axios.create({baseURL: baseURL})

      axiosInstance.get(
        `/front/data/get?token=${accessToken}&id=${datasetID}`,
        {headers: {token: accessToken}}
      ).then((response) => {
        if (response.data.code === 4) {
          const message = `Login information is invalid. Please re-enter the training system!`
          updateError(message)
        } else if (response.data.code !== 0) {
          const message = `Unable to obtain annotation information for dataset ${datasetName}`
          updateError(message)
        } else {
          // console.log(response.data)
          const labels = response.data.data.labels
          rectToolConfig.attributeList = labels.map((e) => {
            return {key: e, value: e}
          })
          // console.log('attributeList', rectToolConfig.attributeList);
          updateJsonStepList(JSON.stringify(buildStepList()))
          updateReady(true)
        }
      }).catch((reason) => {
        const message = reason.toString()
        updateError(message)
      })
    }
  }


  // const goBack = (data) => {
  //   console.log('goBack', data);
  // };

  // return <Annotation error={stateError} ready={stateReady} dataset={dataset} goBack={goBack} jsonStepList={stateJsonStepList} step={1} />;
  return <Annotation
      error={stateError}
      ready={stateReady}
      dataset={dataset}
      jsonStepList={stateJsonStepList}
      step={1}
      viewAugmentation={!!augment}
      lang={lang}
  />;

  // return <div className='App'></div>;
};

export default App;
