// declare global {
//   // reactEnv are expected defined in some `public/env.js`
//   // eslint-disable-next-line no-unused-vars
//   const reactEnv: { [p: string]: string }
// }

const browserEnv = (function () {
  try {
    // if viteEnv not defined. Then means not environment settings.
    // eslint-disable-next-line no-undef
    return reactEnv
  } catch (_) {
    return {}
  }
}())

export const env = process.env

export function loadEnv (name) {
  try {
    const value = browserEnv[name]
    // The browser env must be `true` to work. as env are string any way.
    //   considering all environments are string.
    if (value !== undefined) return value
  } catch (_) {
  }

  try {
    return env[name]
  } catch (_) {
  }

  return undefined
}
