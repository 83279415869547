/**
export declare interface BaseURLOption {
  protocol?: string,
  hostname?: string,
  port?: string|number
  prefix?: string
}
 */

/**
 * Build base URL based on the current window-location
 * @param option set option to cover current location's base-url
 * Call baseUrl({port: 9000}), return the base-url just replaced port
 */
export function buildBaseURL (option) {
  const location = window.location
  let protocol = option?.protocol || location.protocol
  const hostname = option?.hostname || location.hostname
  const port = option?.port || location.port
  let prefix = option?.prefix || ''
  if (!protocol.endsWith(':')) {
    protocol = protocol + ':'
  }
  if (prefix.startsWith('/')) {
    prefix = prefix.slice(1)
  }
  const safePort = port ? `:${port}` : '' // deal with if port never set
  const safePrefix = prefix ? `/${prefix}` : '' // deal with if prefix not set
  return `${protocol}//${hostname}${safePort}${safePrefix}`
}
