import * as Tiff from 'tiff.js'

export const tiffDataURL = (data) => {
  // eslint-disable-next-line padded-blocks
  try {
    // @ts-ignore: TS2304
    // eslint-disable-next-line no-undef, @typescript-eslint/no-namespace
    const tiff = new Tiff({ buffer: data })
    const canvas = tiff.toCanvas()
    return canvas.toDataURL('image/jpeg')
  } catch (e) {
    e = JSON.stringify(e)
    return new Error(`unable to load the image: ${e}`)
  }
}
